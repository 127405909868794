#subscribe {
  background: url("../../assets/images/banner.jpg");
  text-align: center;
  background-size: cover;
  background-attachment: fixed;
  color: var(--color-white);
}
.subscribe h2 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  padding-bottom: 1rem;
}
form > * {
  width: 100%;
  max-width: 50rem;
  margin: 3rem auto;
}

form input {
  padding: 1rem;
  background: #f7f7f7;
  border: 1px solid #777;
  border-radius: 5px 0 0 5px;
  color: #777;
  outline: none;
  width: 80%;
  font-size: 1.4rem;
}

form button {
  padding: 1rem;
  border: 1px solid #777;
  background: red;
  border-radius: 0 5px 5px 0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
}
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icon {
  margin: 2rem;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}
.social-icon:hover {
  background: var(--light-blue);
}

@media screen and (max-width: 600px) {
  form input {
    width: 60%;
  }
}
