.question {
  border: 1px solid var(--light-blue);
  border-radius: 3px;
  width: 70%;
  max-width: 60rem;
  padding: 1rem 0;
  margin: 1rem auto;
}

.question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* padding-bottom: 1rem; */
}

.question-title h4 {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
}

button.question-icon {
  border: 1px solid var(--color-grey);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
