@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
.header-bg {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--dark-blue);
}

a {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--color-white);
  text-decoration: none;
  margin-right: 1rem;
}
a:hover {
  color: orangered;
}
a.btn {
  border-radius: 5px;
  /* margin-left: 1rem; */
  font-weight: 500;
  padding: 5px 8px;
}
a.btn-dark {
  border: 2px solid var(--color-white);
  background: transparent;
}
a.btn-orange {
  border: 2px solid transparent;
  background: orangered;
  color: var(--color-white);
}

#header {
  position: relative;
}
.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.header h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}
.header h1 span {
  display: block;
}
h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
  font-size: 3rem;
  text-transform:lowercase;
}
.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80%;
  height: 1px;
  background: var(--color-white);
}
.header-left p {
  margin: 3rem 0;
  width: 80%;
}
.header-right {
  width: 50%;
}
.floating-icon {
  width: 5rem;
  height: 5rem;
  background: var(--light-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mouse {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-animation: bounce 0.8s ease infinite alternate;
          animation: bounce 0.8s ease infinite alternate;
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}
@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }
  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
  .header-left {
    margin-bottom: 4rem;
  }
  .header h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right {
    width: 40%;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-text {
  font-size: 4rem;
  font-weight: 300;
  color: var(--color-white);
}
.logo-text img {
  width: 100%;
}
.logo-text span {
  font-weight: 200;
  color: orangered;
  font-size: 1.5rem;
  font-style: italic;
}
.nav-links {
  display: flex;
}
.nav-links li {
  margin-left: 1rem;
  list-style: none;
}
.nav-links a {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--color-white);
  text-decoration: none;
  margin-right: 1rem;
}
.nav-links a:hover {
  color: orangered;
}
a.btn {
  border-radius: 5px;
  /* margin-left: 1rem; */
  font-weight: 500;
  padding: 5px 8px;
}
a.btn-dark {
  border: 2px solid var(--color-white);
  background: transparent;
}
a.btn-orange {
  border: 2px solid transparent;
  background: orangered;
  color: var(--color-white);
}
.navbar .menu-icons {
  display: none;
}
.nav-btn {
  width: 20%;
}
@media screen and (max-width: 769px) {
  .nav-btn {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .nav-btn {
    display: block;
  }
  .nav-links {
    display: none;
    border: 2px solid var(--color-white);
    /* z-index: 111; */
  }
  .navbar .menu-icons {
    display: block;
  }
  .navbar #nav-links-mobile,
  .navbar #nav-links-mobile-hide {
    display: block;
    position: absolute;
    left: 0;
    top: 8rem;
    width: 100%;
    background: var(--dark-blue);
    border-top: 1px solid var(--color-white);
    padding-bottom: 5px;
    transition: all 0.3s;
  }
  .navbar #nav-links-mobile {
    -webkit-animation: slide-in 0.5s ease-in-out;
            animation: slide-in 0.5s ease-in-out;
  }
  @-webkit-keyframes slide-in {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes slide-in {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  .navbar #nav-links-mobile-hide {
    -webkit-animation: slide-out 0.5s ease-in-out;
            animation: slide-out 0.5s ease-in-out;
    left: -100%;
  }
  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }
  @keyframes slide-out {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }
  .navbar #nav-links-mobile li,
  .navbar #nav-links-mobile-hide li {
    text-align: center;
    padding: 1rem 0;
    border-bottom: 1px solid var(--color-white);
    margin: 0 3rem;
  }
  .navbar #nav-links-mobile li:last-child {
    border-bottom: none;
  }
}

.features-content {
  display: flex;
  justify-content: center;
}
.features-left {
  flex: 1 1;
  text-align: center;
}
.features-left img {
  width: 65%;
}
.features-right {
  flex: 1 1;
}
@media screen and (max-width: 600px) {
  .features-content {
    flex-direction: column;
  }
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.feature-icon {
  position: relative;
  margin-right: 2rem;
}
.inner-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.feature-text h3 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--light-blue);
}

.features-content {
  display: flex;
  justify-content: center;
}
.features-left {
  flex: 1 1;
  text-align: center;
}
.features-left img {
  width: 65%;
}
.features-right {
  flex: 1 1;
}
@media screen and (max-width: 600px) {
  .features-content {
    flex-direction: column;
  }
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.feature-icon {
  position: relative;
  margin-right: 2rem;
}
.inner-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.feature-text h3 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--light-blue);
}

#community {
  background: linear-gradient(90deg, rgba(14,13,15,1) 16%, rgba(11,121,9,1) 58%, rgba(14,15,15,1) 93%);
  text-align: center;
  color: var(--color-white);
}
.community h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  padding-bottom: 1rem;
  position: relative;
}
.community h2::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 10%;
  height: 1px;
  background: var(--color-white);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.community-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}
.community-icon {
  margin: 1rem 2rem;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 15rem;
  padding: 1rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
}
.community-icon p {
  margin-left: 5px;
}

#subscribe {
  background: url(/static/media/banner.a1241ba4.jpg);
  text-align: center;
  background-size: cover;
  background-attachment: fixed;
  color: var(--color-white);
}
.subscribe h2 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  padding-bottom: 1rem;
}
form > * {
  width: 100%;
  max-width: 50rem;
  margin: 3rem auto;
}

form input {
  padding: 1rem;
  background: #f7f7f7;
  border: 1px solid #777;
  border-radius: 5px 0 0 5px;
  color: #777;
  outline: none;
  width: 80%;
  font-size: 1.4rem;
}

form button {
  padding: 1rem;
  border: 1px solid #777;
  background: red;
  border-radius: 0 5px 5px 0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
}
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icon {
  margin: 2rem;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}
.social-icon:hover {
  background: var(--light-blue);
}

@media screen and (max-width: 600px) {
  form input {
    width: 60%;
  }
}

.question {
  border: 1px solid var(--light-blue);
  border-radius: 3px;
  width: 70%;
  max-width: 60rem;
  padding: 1rem 0;
  margin: 1rem auto;
}

.question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* padding-bottom: 1rem; */
}

.question-title h4 {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
}

button.question-icon {
  border: 1px solid var(--color-grey);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

section#footer {
  background: var(--dark-blue);
  color: #fff;
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-row-gap: 2rem;
  row-gap: 2rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}

/* .footer > * {
    flex: 1;
    justify-self: center;
  } */

.footer h4 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer-box .footer-links a {
  font-size: 1.2rem;
}
.footer-box img {
  width: 100%;
}
.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-contact p {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
  .footer-box img {
    width: 70%;
  }
}

:root {
  --font-family: "Poppins", sans-serif;
  /* --dark-blue: #0a1930; */
  /* background: rgb(2,0,36); */
  --dark-blue: linear-gradient(90deg, rgba(14,13,15,1) 16%, rgba(11,121,9,1) 58%, rgba(14,15,15,1) 93%);
  --light-blue: #1f93ff;

  --color-white: #fff;
  --dark-text: #333;
  --form-button: #2da5ff;
  --color-grey: #eae6eb;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: "Poppins", sans-serif;
  font-family: var(--font-family);
}

section {
  width: 100%;
  padding: 8rem 0;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Text styles */
.u-text-small {
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 300;
}

.u-text-dark {
  color: var(--dark-text);
}
.u-text-light {
  color: var(--dark-text);
}

.u-text-center {
  text-align: center;
}

/* Title */
.u-title {
  text-align: center;
  margin-bottom: 4rem;
}

.u-title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 1.5rem 0;
}

.u-title p {
  width: 50%;
  min-width: 40rem;
  margin: 0 auto;
  color: var(--dark-text);
}
/* text css styles */
.u-text-small {
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 300;
}

.u-text-light {
  color: var(--color-white);
}

.u-text-dark {
  color: var(--dark-text);
}

/* title */
.u-title {
  text-align: center;
  margin-bottom: 4rem;
}
.u-title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  /* text-transform: uppercase; */
  color: var(--dark-blue);
  margin: 1.5rem 0;
}
.u-title p {
  width: 50%;
  min-width: 40rem;
  margin: auto;
}

