#community {
  background: linear-gradient(90deg, rgba(14,13,15,1) 16%, rgba(11,121,9,1) 58%, rgba(14,15,15,1) 93%);
  text-align: center;
  color: var(--color-white);
}
.community h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  padding-bottom: 1rem;
  position: relative;
}
.community h2::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 10%;
  height: 1px;
  background: var(--color-white);
  transform: translateX(-50%);
}
.community-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}
.community-icon {
  margin: 1rem 2rem;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 15rem;
  padding: 1rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
}
.community-icon p {
  margin-left: 5px;
}
