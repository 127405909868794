@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --font-family: "Poppins", sans-serif;
  /* --dark-blue: #0a1930; */
  /* background: rgb(2,0,36); */
  --dark-blue: linear-gradient(90deg, rgba(14,13,15,1) 16%, rgba(11,121,9,1) 58%, rgba(14,15,15,1) 93%);
  --light-blue: #1f93ff;

  --color-white: #fff;
  --dark-text: #333;
  --form-button: #2da5ff;
  --color-grey: #eae6eb;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: var(--font-family);
}

section {
  width: 100%;
  padding: 8rem 0;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}
