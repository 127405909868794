section#footer {
  background: var(--dark-blue);
  color: #fff;
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  row-gap: 2rem;
  column-gap: 2rem;
}

/* .footer > * {
    flex: 1;
    justify-self: center;
  } */

.footer h4 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer-box .footer-links a {
  font-size: 1.2rem;
}
.footer-box img {
  width: 100%;
}
.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-contact p {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
  .footer-box img {
    width: 70%;
  }
}
